import * as React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Layout, Grid, Cell, CellRow } from '@components'
import { palette } from '@theme'
import { StoreLocator } from '@utilities'

interface PageProps {
  data: {
    sanityPage: GatsbyTypes.SanityPage
  }
}

const LocatorPage = ({
  data: {
    sanityPage: { title, gridItems },
  },
}: PageProps) => {
  return (
    <Layout backgroundColor={palette.pink}>
      <Helmet>
        <title>Bawi Agua Fresca: {title}</title>
      </Helmet>

      <Grid>
        {gridItems?.map((item) => {
          if (!item) return
          if (item._type === 'cell')
            return (
              <Cell key={item._key} {...(item as GatsbyTypes.SanityCell)} />
            )
          if (item._type === 'cellRow')
            return (
              <CellRow
                key={item._key}
                {...(item as GatsbyTypes.SanityCellRow)}
              />
            )
        })}
        <LocatorPlaceholder>
          <StoreLocator />
        </LocatorPlaceholder>
      </Grid>
    </Layout>
  )
}

const LocatorPlaceholder = styled.div`
  grid-column: 1 / span 6;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  @media only screen and (min-width: 744px) {
    min-height: 550px;
    grid-column: 1 / span 12;
  }
  border: 0px !important;
`

export const query = graphql`
  query LocatorPage {
    sanityPage(slug: { current: { eq: "find-bawi" } }) {
      ...pageFields
    }
  }
`

export default LocatorPage
